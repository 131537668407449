<template>
    <ViewTitle title="客戶服務 | 保單查詢" />
    <InsuranceFilter
        :listData="listData"
        :isLoading="isTableLoading"
        :name="$route.params.name"
        @show-data-table="showDataTable"
        v-model:filterPayloadProp="filterPayload"
        v-model:filterLogProp="filterLog"
    />
    <InsuranceTable
        :listData="listData"
        :isLoading="isTableLoading"
        :eventList="checkInsServices"
        :filterLog="filterLog"
        :payload="filterPayload"
        :remindBeforeDays="remindBeforeDays"
        :hasReminderError="hasRemindSettingError"
        :manCode="parseInt(filterPayload.manCode)"
        @getinsuranceid="showEventModal"
        @showsuppliersyncmodal="showSupplierSyncModal"
        @reload="showDataTable"
    />
    <component
        :is="currentModal"
        :isLoading="isTableLoading"
        v-model="isShowEventModal"
        :insId="insId"
        :manCode="this.filterPayload.manCode"
        @update-ins-services="updateInsServices"
        page="insurance"
        :insNo="insId"
        :supplier="supplier"
        :supplierCode="supplierCode"
        :customerId="customerId"
        :customerName="customerName"
        :dataStatus="dataStatus"
    >
    </component>
</template>

<script>
import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import InsuranceFilter from '@/containers/tia/InsuranceFilter.vue'
import InsuranceTable from '@/containers/tia/InsuranceTable.vue'
import InsuranceNoticeModal from '@/containers/tia/InsuranceNoticeModal.vue'
import SupplierSyncModal from '@/containers/tia/SupplierSyncModal.vue'
import {
    getTiaInsuranceListAPI,
    getTiaInsuranceSettingAPI
} from '@/assets/javascripts/api'

export default {
    name: 'Insurance',
    components: {
        ViewTitle,
        InsuranceFilter,
        InsuranceTable,
        InsuranceNoticeModal,
        SupplierSyncModal
    },
    provide() {
        return {
            showDataTableProvide: this.showDataTable
        }
    },
    methods: {
        showDataTable: async function () {
            let selectedAccounts = this.filterPayload.manCodes.length
            if (selectedAccounts <= 0) return
            let isSkipLoading = this.$route.params.id ? true : false
            const payload = _.cloneDeep(this.filterPayload)
            try {
                if (!isSkipLoading) this.isTableLoading = true
                this.$showLoading()
                if (this.filterPayload) await this.getRemindBeforeDays()
                let response = await this.$getResponse(
                    payload,
                    this.pageInfo,
                    getTiaInsuranceListAPI
                )
                this.listData = response.data.insurances
            } catch (error) {
                this.listData = []
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        },
        showEventModal: function (currentRow) {
            this.insId = currentRow.mainCode
            this.filterPayload.manCode = currentRow.manCode
            this.currentModal = 'InsuranceNoticeModal'
            this.isShowEventModal = true
        },
        showSupplierSyncModal: function (val) {
            const insurance = this.listData.find(
                (item) => item.mainCode === val
            )
            this.insId = insurance.insNo
            this.supplierCode = insurance.supplierCode
            this.supplier = insurance.supplier
            this.customerId =
                insurance.insuredId === insurance.payerId
                    ? [insurance.insuredId]
                    : [insurance.insuredId, insurance.payerId]
            this.customerName = {
                insured: insurance.insured ?? null,
                payer: insurance.payer ?? null
            }
            this.dataStatus = insurance.fromSupplierDataStatus
            this.currentModal = 'SupplierSyncModal'
            this.isShowEventModal = true
        },
        updateInsServices: function (completed) {
            this.eventList[this.insId] =
                this.eventList[this.insId] > 0
                    ? this.eventList[this.insId] + completed.length
                    : completed.length
            let list = _.cloneDeep(this.checkInsServices)
            list = Object.assign(list, this.eventList)
            this.checkInsServices = list
        },
        getRemindBeforeDays: async function () {
            try {
                const res = await getTiaInsuranceSettingAPI(
                    this.$store.state.userInfo.code
                )
                this.remindBeforeDays = res.data.settings.remindBeforeDays
            } catch (error) {
                this.hasRemindSettingError = true
                console.log(error)
            }
        }
    },
    data() {
        return {
            pageInfo: {
                apiAlias: 'list',
                apiName: '保單列表'
            },
            isTableLoading: false,
            isShowEventModal: false,
            isShowSupplierSyncModal: false,
            filterPayload: {
                manCode: ''
            },
            currentModal: null,
            listData: [],
            insId: null,
            supplierCode: null,
            supplier: null,
            customerId: null,
            customerName: {},
            selectedNotices: [],
            eventList: {},
            checkInsServices: {},
            dataStatus: null,
            filterLog: {},
            hasRemindSettingError: false,
            remindBeforeDays: 0
        }
    }
}
</script>

<style lang="scss" scoped></style>
